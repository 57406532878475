@import './variables';

// + Header
header {
  position: fixed;
  left: 0;
  width: 100%;
  height: var(--header-height);
  background: transparent;
  padding: var(--safe-area-inset-top) 16px 0;
  z-index: 100;
  transition: all 0.4s ease-out;

  svg {
    width: 183px;
    height: 30px;
    path {
      transition: all 0.4s ease-out;
    }
  }

  &:hover,
  &.white-bg {
    background: $clr-header-gray;
    .inner-list__wrapper span,
    nav li a,
    .menu-link-btn {
      color: rgba($clr-text-primary, 0.3);

      &:hover {
        color: $clr-primary;
      }
    }

    .btn-hmb {
      span {
        background: currentColor;
      }
    }

    svg {
      width: 183px;
      height: 30px;

      path:first-child {
        fill: $clr-text-primary;
      }
    }

    @media (min-width: 1025px) {
      .inner-list__wrapper span,
      nav li a,
      .menu-link-btn {
        color: $clr-gray-dark;
      }
    }
  }

  &.header-hide {
    top: calc(-1 * var(--header-height));
  }
  &.header-visible {
    top: 0;
  }

  @media (min-width: 744px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 1025px) {
    padding-left: 7%;
    padding-right: 7%;
  }

  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .header-logo {
    img {
      width: 183px;
      height: 30px;
    }
  }

  // + Sandwich button
  .btn-hmb {
    position: relative;
    display: block;
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    line-height: 1;
    border: 0;
    background: none;
    padding: 0;
    overflow: hidden;

    span {
      background: $clr-white;
      border-radius: 2px;
      display: block;
      height: 2px;
      position: absolute;
      right: 4px;
      transform: rotate(0deg);
      transition: top 0.25s ease-in-out, transform 0.25s ease-in-out;
      width: 24px;

      &:first-child {
        top: 8px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 15px;
      }
      &:nth-child(4) {
        top: 22px;
      }
    }

    &.open {
      span {
        background: currentColor;
        &:first-child {
          left: 50%;
          top: 9px;
          width: 0;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          left: 50%;
          top: 9px;
          width: 0;
        }
      }
    }
    @media (min-width: 1025px) {
      display: none;
      pointer-events: none;
    }
  }
  // - Sandwich button

  // + Button as menu link
  .menu-link-btn {
    height: 100%;
    border: none;
    background: none;

    &::after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../images/chevron-down.svg) no-repeat center;
      vertical-align: middle;
      margin-left: 20px;
      transition: all 0.4s ease-out;
    }

    @media (min-width: 1025px) {
      display: none;
      pointer-events: none;
      &::after {
        margin-left: 0;
      }
    }
  }
  // - Button as menu link

  nav {
    position: absolute;
    top: calc(var(--header-height) - 2px);
    left: 0;
    right: 0;
    display: none;
    background: $clr-white;
    box-shadow: 0px 12px 16px -4px rgba($clr-gray-dark, 0.08),
      0px 4px 6px -2px rgba($clr-gray-dark, 0.03);
    transition: all 0.2s ease-out;

    > div {
      border-top: 1px solid $clr-border-secondary;
      padding: 24px 16px;

      button {
        width: 100%;
      }
    }

    ul {
      padding: 24px 0;
    }

    li {
      a {
        display: block;
        color: $clr-white;
        font-family: ManropeSemiBold;
        padding: 12px 16px;

        &:hover,
        &:focus-visible {
          color: $clr-primary;
        }

        &.active {
          color: $clr-gray-dark;
        }
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      a.menu-link-desktop {
        display: inline-block;
        padding-right: 8px;
      }
    }

    &.open {
      display: block;
    }

    @media (min-width: 744px) {
      > div {
        padding: 24px 60px;

        button {
          width: auto;
        }
      }

      li {
        a,
        .menu-link-btn {
          padding: 12px 60px;
        }
        .menu-link-btn {
          padding-left: 8px;
          margin-left: 16px;
        }
      }
    }

    @media (min-width: 1025px) {
      position: static;
      display: block;
      justify-content: space-between;
      flex-grow: 0.5;
      background: none;
      padding: 0;
      box-shadow: none;

      > div {
        border: none;
        padding: 0;
      }

      &,
      &.open,
      ul {
        display: flex;
        align-items: center;
        height: 100%;
      }
      li {
        padding-right: 24px;

        a,
        a.menu-link-desktop {
          position: relative;
          display: inline-block;
          line-height: 80px;
          text-align: center;
          padding: 0;
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 24px;
            width: 100%;
            height: 1px;
            background: transparent;
          }

          &.active,
          &:hover,
          &:focus-visible {
            color: $clr-primary;
            background: none;
            &::after {
              background: currentColor;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    // + Inner List - Services
    .inner-list__wrapper {
      .inner-list {
        height: 0;
        padding: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;

        li {
          &:not(:last-child) {
            padding-right: 48px;
          }
        }

        a {
          color: $clr-text-primary;
        }
      }

      &.open {
        .menu-link-btn {
          color: $clr-text-primary;

          &::after {
            transform: rotate(-180deg);
          }
        }
      }

      &.open {
        .inner-list {
          height: auto;
        }
      }

      @media (min-width: 1025px) {
        .inner-list {
          position: absolute;
          top: calc(var(--header-height) - 2px);
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          background: $clr-header-gray;

          li {
            &:not(:last-child) {
              padding-right: 48px;
            }
          }

          a {
            color: $clr-text-primary;
          }
        }

        &.open,
        &:hover {
          .menu-link-btn {
            color: $clr-primary;

            &::after {
              transform: none;
            }
          }
          .inner-list {
            height: 104px;
          }
        }
      }
    }
    // - Inner List - Services
  }
}
// - Header
