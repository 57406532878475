:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);

  --header-height: 72px;
  --max-width: 1440px;

  --fs-H1: 32px;
  --fs-H2: 32px;
  --fs-H3: 24px;
  --fs-H4: 20px;

  @media (min-width: 744px) {
    --fs-H1: 80px;
    --fs-H2: 56px;
    --fs-H3: 24px;
    --fs-H4: 20px;
  }

  @media (min-width: 1025px) {
    --header-height: 80px;
  }
}
