@import './reset';
@import './variables';
@import './cssvars';
@import './fonts';
@import './buttons-links';
@import './layout';

body {
  font-family: Manrope, 'SF Pro Text', Roboto, 'Helvetica Neue', Helvetica, 'Segoe UI', SegoeUI,
    Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $clr-white;

  #root {
    padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom)
      var(--safe-area-inset-left);
  }

  a,
  button {
    &,
    &::before,
    &::after {
      cursor: pointer;
      transition: all 0.2s ease-out;
    }
  }

  h1,
  h2,
  h3 {
    font-family: ManropeExtraBold;
    color: $clr-gray-dark;
  }
  h1 {
    font-size: var(--fs-H1);
    line-height: 1.14;
    margin: 0 auto;
  }
  h2 {
    font-size: var(--fs-H2);
    line-height: 1.2;
  }
  h3 {
    font-size: var(--fs-H3);
    line-height: 1.25;
  }
  h4 {
    font-size: var(--fs-H4);
  }

  img {
    object-fit: cover;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    @media (min-width: 744px) {
      font-size: 16px;
    }
  }

  .large-p {
    font-family: ManropeMedium;
    font-size: 16px;
    line-height: 1.6;

    @media (min-width: 744px) {
      font-size: 20px;
    }
  }

  p.page-description {
    @extend .large-p;
    text-align: center;
    margin: 0 auto;
  }
}
.overflow-hidden {
  overflow: hidden;
}
