$clr-white: #fff;
$clr-white-60: rgba($clr-white, 0.6);
$clr-border-primary: #e0e2ec;
$clr-border-secondary: #eaecf0;
$clr-gray-dark: #2f3033;
$clr-text-primary: #051937;
$clr-text-title: #2d3038;
$clr-text-tertiary: #475467;
$clr-light-green: #f0f8f6;

$clr-brand-primary: #2da287;

$clr-contrast-text-secondary: #092550;

$clr-primary: #0bc178;
$clr-primary-hover: #006c51;
$clr-primary-active: #00513c;

$clr-secondary-border: #5dbba6;
$clr-secondary-hover: #effcf8;
$clr-secondary-active: #d8f8ef;

$clr-border-focus: #00587c;

$clr-disabled-primary: #8d9199;
$clr-disabled-border: #c3c6cf;
$clr-bg-secondary: #f6f6fc;

$clr-footer-bg: #f9fafb;
$clr-header-gray: #f5f5f7;
$clr-gray-hover-bg: #e2e2e5;
