button {
  height: 48px;
  font-family: ManropeSemiBold;
  font-size: 16px;
  border-radius: 8px;
  padding: 0 16px;

  &.btn-primary {
    color: $clr-white;
    border: 1px solid $clr-primary;
    background: $clr-primary;

    &:focus-visible {
      background: $clr-primary-active;
      border-color: $clr-primary-active;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $clr-primary-hover;
        border-color: $clr-primary-hover;
      }
    }

    &:disabled {
      background: $clr-disabled-primary;
      border-color: $clr-disabled-primary;
    }
  }

  &.btn-secondary {
    color: $clr-primary;
    border: 1px solid $clr-secondary-border;
    background: $clr-white;

    &:focus-visible {
      color: $clr-primary-active;
      background: $clr-secondary-active;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $clr-primary-hover;
        background: $clr-secondary-hover;
      }
    }

    &:disabled {
      color: $clr-disabled-primary;
      background: $clr-bg-secondary;
      border-color: $clr-disabled-border;
    }
  }
}

a {
  &.btn-primary {
    display: inline-block;
    height: 48px;
    font-family: ManropeSemiBold;
    font-size: 16px;
    line-height: 48px;
    border-radius: 8px;
    padding: 0 24px;
    color: $clr-white;
    border: 1px solid $clr-primary;
    background: $clr-primary;

    &:focus-visible {
      background: $clr-primary-active;
      border-color: $clr-primary-active;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $clr-primary-hover;
        border-color: $clr-primary-hover;
      }
    }
  }
}
