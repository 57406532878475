@import './variables';

* {
  font-family: Manrope, sans-serif;
  font-variant-ligatures: none;
  box-sizing: border-box;
}

.img-logo {
  width: 152px;
  height: 25px;
  object-fit: contain;
}

main {
  min-height: 100vh;
}

footer {
  * {
    color: $clr-white;
  }
  a {
    display: inline-block;
    color: $clr-white;
    &.active {
      color: $clr-primary;
    }
    &:hover,
    &:focus-visible {
      color: $clr-primary;
    }
  }
}
