@font-face {
  font-family: Manrope;
  src: local('Manrope Regular'), local('Manrope-Regular'),
    url('../fonts/Manrope-Regular.woff2') format('woff2'),
    url('../fonts/Manrope-Regular.woff') format('woff'),
    url('../fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ManropeMedium;
  src: local('Manrope Medium'), local('Manrope-Medium'),
    url('../fonts/Manrope-Medium.woff2') format('woff2'),
    url('../fonts/Manrope-Medium.woff') format('woff'),
    url('../fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ManropeSemiBold;
  src: local('Manrope SemiBold'), local('Manrope-SemiBold'),
    url('../fonts/Manrope-SemiBold.woff2') format('woff2'),
    url('../fonts/Manrope-SemiBold.woff') format('woff'),
    url('../fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ManropeExtraBold;
  src: local('Manrope ExtraBold'), local('Manrope-ExtraBold'),
    url('../fonts/Manrope-ExtraBold.woff2') format('woff2'),
    url('../fonts/Manrope-ExtraBold.woff') format('woff'),
    url('../fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icon-font-hcc';
  src: url('../fonts/icon-font-hcc.eot');
  src: url('../fonts/icon-font-hcc.eot') format('embedded-opentype'),
    url('../fonts/icon-font-hcc.ttf') format('truetype'),
    url('../fonts/icon-font-hcc.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}
